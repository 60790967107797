<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="音频名稱" prop="audioName">
                <a-input v-model="queryParam.audioName" placeholder="请输入音频名稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="類型" prop="type">
                <a-select v-model="queryParam.inOut">
                  <a-select-option :value="1">
                    公共音乐
                  </a-select-option>
                  <a-select-option :value="2">
                    用戶上传
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()">
          <a-icon type="plus" />新增
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <img style="width: 48px;height: 48px;" preview="封面" :src="record.audioPic" v-if="record.audioPic != null && record.audioPic != ''" slot="audioPic" slot-scope="text, record">

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)">
            <a-icon type="delete" />刪除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAudio,listAudio, delAudio } from '@/api/platform/audio'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Audio',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        userId: null,
        type: null,
        audioName: null,
        audioFormat: null,
        audioUrl: null,
        audioDuration: null,
        audioPic: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '音频名稱',
          dataIndex: 'audioName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '封面',
          dataIndex: 'audioPic',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "audioPic"}
        },
        {
          title: '類型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "公共音乐"
            }
            if (value == 2) {
              return "用戶上传"
            }
          }
        },
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢音频业务列表 */
    getList () {
      this.loading = true
     pageAudio(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        type: undefined,
        audioName: undefined,
        audioFormat: undefined,
        audioUrl: undefined,
        audioDuration: undefined,
        audioPic: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAudio(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/audio/export', {
            ...that.queryParam
          }, `音频业务_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
